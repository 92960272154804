import useStore from '@/hooks/useStore'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DoneIcon from '@mui/icons-material/Done'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import { useRouter } from 'next/router'

const Home = () => {
  const data = useStore((state) => state.home?.aboutUs) as AboutUs
  const { push } = useRouter()
  return (
    <Container id="about-us" sx={{ py: 6 }}>
      <Grid container alignItems="center" spacing={8}>
        <Grid item xs={12} md={6} container>
          <Typography variant="h4" fontWeight={700} gutterBottom>
            {data?.title}
          </Typography>
          <Typography
            color="text.secondary"
            gutterBottom
            component="div"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></Typography>
          <Grid container spacing={2} sx={{ my: 2 }}>
            {data?.items?.map((el, i) => (
              <Grid item xs={12} md={6} key={i}>
                <Stack spacing={1} direction="row">
                  <DoneIcon color="primary" />
                  <Typography color="text.secondary">{el.item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIcon />}
            onClick={() => push(data?.button?.path)}
            sx={{ px: 6, py: 2, borderRadius: 50, mt: 4 }}
          >
            {data?.button?.label}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              width: 1,
              minHeight: 600,
              bgcolor: 'primary.main',
              position: 'relative',
            }}
          >
            {data?.media && (
              <Image
                src={getStrapiImage(data?.media)}
                blurDataURL={getStrapiImagePlaceholder(data?.media)}
                placeholder="blur"
                alt={data?.media?.alternativeText}
                objectFit="cover"
                layout="fill"
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
